import { template as template_a8c64e004cda4a828227e6247b1503ed } from "@ember/template-compiler";
const FKLabel = template_a8c64e004cda4a828227e6247b1503ed(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
