import { template as template_b7ecb1bbe40e4b1fa61a5f202f04c6c0 } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class FKControlCustom extends Component {
    static controlType = "custom";
    static{
        template_b7ecb1bbe40e4b1fa61a5f202f04c6c0(`
    <div class="form-kit__control-custom">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
