import { template as template_97ea65e8890e4e9e85da580db990c2ba } from "@ember/template-compiler";
const FKText = template_97ea65e8890e4e9e85da580db990c2ba(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
