import { template as template_edb191be3f194ca994bed709e0cfb3b7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_edb191be3f194ca994bed709e0cfb3b7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
