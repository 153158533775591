import { template as template_fe4bb828e36a42618914620b979e1bd2 } from "@ember/template-compiler";
const SidebarSectionMessage = template_fe4bb828e36a42618914620b979e1bd2(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
